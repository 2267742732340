import { Grid } from "@mui/material";
import useScreenSize from "components/customHooks/useScreenSize";
import { useState } from "react";
import HeroAnimation from "../HeroAnimation";
import HeroReusableV1 from "./HeroReusableV1";

const HeroControl = () => {
  const screenSize = useScreenSize();
  const [paused, setPaused] = useState(true);
  const versionNumber = "000";

  const getMarginValue = () => {
    return screenSize === "smallScreen" ? "0" : "auto";
  };

  const getAnimationMarginValue = () => {
    return screenSize === "smallScreen" ? "7.35rem auto 1.5rem" : "auto";
  };

  return (
    <div className="layout-container">
      <Grid
        onMouseOver={() => setPaused(false)}
        onMouseOut={() => setPaused(true)}
        container
        alignItems="center"
        justifyContent="center"
      >
        {/* Short text + buttons */}
        <Grid item lg={5} md={12} sm={12} margin={getMarginValue()} >
          <HeroReusableV1
            headerWithDesc={false}
            versionNumber={versionNumber}
          />
        </Grid>
        {/* Animation */}
        <Grid item lg={7} md={12} sm={12} margin={getAnimationMarginValue()} >
          <Grid justifyContent="center" alignItems="center" container>
            <HeroAnimation
              paused={screenSize === "smallScreen" ? false : paused}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default HeroControl;

import React, { useEffect, useState } from "react";
import useScrollY from "../../components/customHooks/useScrollY";
import HeroImage from "./Hero/hero-compressed.png";
import Image from "next/legacy/image";
import useScreenSize from "../../components/customHooks/useScreenSize";

import dynamic from "next/dynamic";
import { useMouseOver } from "../../components/customHooks/useMouseOver";

const LoadingImage = (
  <Image priority src={HeroImage} alt="loading"/>
);

const DynamicComponentWithCustomLoading = dynamic(
  () => import("./Hero/Animation"),
  {
    loading: () => <div className="loadingPicture">{LoadingImage}</div>,
  }
);

const HeroAnimation = () => {
  const scrollY = useScrollY();
  const isMouseOver = useMouseOver();
  const [load, setLoad] = useState(false);
  const screen = useScreenSize();

  useEffect(() => {
    if (scrollY > 0 || isMouseOver) {
      setLoad(true);
    }
  }, [scrollY, isMouseOver]);

  return (
    <div
      style={{
        position: "relative",
        height: screen === "smallScreen" ? "auto" : "525px",
      }}
    >
      {load && <DynamicComponentWithCustomLoading />}
      {!load && <div className="loadingPicture">{LoadingImage}</div>}
    </div>
  );
};

export default HeroAnimation;
